<template>
  <div>
    <b-card title="Topic List">
      <b-button
        @click="cleanUpForm()"
        v-b-modal.form-topic
        variant="primary"
        class="btn waves-effect waves-float waves-light btn-primary mb-2"
      >
        Create Topic
      </b-button>
      <TableTopic
        :topicData="topicData"
        :is-loading="isLoading"
        :get-data="getData"
        :formPayload="formPayload"
        :edit-item="editItem"
        :edit-id="editId"
        :create-item="createItem"
        :deleteItem="deleteItem"
        :update-item="updateItem"
        :validations="validations"
      />
    </b-card>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
  configure,
} from "vee-validate";
import { required } from "@validations";
import TableTopic from "@/components/users/TableTopic.vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
    dirty: ["is-dirty", "is-dirty"], // multiple classes per flag!
    // ...
  },
});
import {
  BCard,
  BFormGroup,
  BFormRadio,
  BButton,
  BRow,
  BSpinner,
  BCol,
  BFormFile,
  BFormCheckbox,
  BBadge,
  VBModal,
} from "bootstrap-vue";
import { successNotification, errorNotification } from "@/auth/utils";
export default {
  components: {
    BCard,
    BRow,
    BSpinner,
    BCol,
    BFormGroup,
    BFormRadio,
    BFormCheckbox,
    BFormFile,
    vSelect,
    BButton,
    BBadge,
    TableTopic,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      required,
      detailUser: {},
      topicData: [],
      formPayload: Object,
      isLoading: false,
      validations: "",
      editId: null,
    };
  },

  setup() {
    return {
      successNotification,
      errorNotification,
    };
  },

  created() {
    this.loadDetail();
  },

  methods: {
    handleBackButton() {
      history.back();
    },
    loadDetail() {
      const params = this.$route.params.uuid;
      this.$http
        .get("/api/v1/admin/users/" + params)
        .then((response) => {
          this.detailUser = response.data.data;
          this.getData();
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    getData() {
      this.isLoading = true;
      this.$http
        .get("/api/v1/mentor/mentorship/topic")
        .then((response) => {
          this.topicData = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    cleanUpForm() {
      this.editId = null;
      this.formPayload = {
        title: "",
        mentorship_duration: "",
      };
      this.validations = "";
    },
    editItem(item) {
      (this.editId = item.uuid),
        (this.formPayload = {
          title: item.title,
          mentorship_duration: item.mentorship_duration,
        });
      this.$bvModal.show("form-topic");
    },
    updateItem() {
      this.isLoading = true;
      this.$http
        .post(
          `/api/v1/mentor/mentorship/topic/${this.editId}`,
          this.formPayload
        )
        .then((response) => {
          this.$bvModal.hide("form-topic");
          this.getData();
          successNotification(this, "Success", "Topic successfully updated!");
          this.cleanUpForm();
          this.isLoading = false;
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.meta &&
            error.response.data.meta.messages
          ) {
            errorNotification(this, "Error", error.response.data.meta.messages);
          } else if (
            error.response &&
            error.response.data &&
            error.response.data.meta &&
            error.response.data.meta.validations
          ) {
            this.validations = error.response.data.meta.validations;
            errorNotification(
              this,
              "Error",
              "Form Tidak Boleh ada yang kosong"
            );
          }
          this.isLoading = false;
        });
    },
    createItem() {
      this.isLoading = true;
      this.$http
        .post("/api/v1/mentor/mentorship/topic", this.formPayload)
        .then((response) => {
          this.$bvModal.hide("form-topic");
          this.getData();
          successNotification(this, "Success", "Topic successfully created!");
          this.cleanUpForm();
          this.isLoading = false;
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.meta &&
            error.response.data.meta.messages
          ) {
            errorNotification(this, "Error", error.response.data.meta.messages);
          } else if (
            error.response &&
            error.response.data &&
            error.response.data.meta &&
            error.response.data.meta.validations
          ) {
            this.validations = error.response.data.meta.validations;
            errorNotification(
              this,
              "Error",
              "Form Tidak Boleh ada yang kosong"
            );
          }
          this.isLoading = false;
        });
    },
    deleteItem(id) {
      let idMentor = this.$route.params.uuid;
      this.$swal({
        title: "Are you sure?",
        text: "Are you sure to delete this Topic?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.isLoading = true;
          this.$http
            .delete(`/api/v1/admin/mentorship/topic/${idMentor}/${id}`)
            .then((response) => {
              this.getData();
              this.$swal({
                icon: "success",
                title: "Success!",
                text: "Topic successfully deleted",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.isLoading = false;
            })
            .catch((error) => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(
                  this,
                  "Oops!",
                  error.response.data.meta.messages
                );
              }
              this.isLoading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.image_galleries {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 30px;
}
</style>
